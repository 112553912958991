<template>
<div>
  <columns>
    <column>
      <text-input
        classes="is-rounded is-medium"
        required
        v-model="option.ok_label"
        :error="$root.errors['handler_payload.ok_label']"
        description="The text displayed for a favourable result">Ok Label</text-input>
    </column>
    <column>
      <text-input
        classes="is-rounded is-medium"
        required
        v-model="option.failure_label"
        :error="$root.errors['handler_payload.failure_label']"
        description="The text displayed for a negative result">Failure Label</text-input>
    </column>
    <column>
      <text-input
        classes="is-rounded is-medium has-addons"
        :disabled="!option.with_na"
        v-model="option.na_label"
        :error="$root.errors['handler_payload.na_label']"
        description="The text displayed for a result with a neutral outcome">
        Not Applicable Label <span class="has-text-weight-light">(optional)</span>
        <template #right>
          <action-button
            class="is-rounded is-medium"
            @click="option.with_na = !option.with_na">
            <icon :icon="option.with_na ? 'eye' : 'eye-slash'"/>
          </action-button>
        </template>
      </text-input>
    </column>
  </columns>  
</div>  
</template>
<script>
import { mapGetters } from 'vuex'
import { MultipleChoiceCheckpoint } from '@/store/models/Checkpoint.js'

export default {
  
  props: {
    builderPayload: {
      type: [Object, Array],
      default: () => ({})
    }
  },

  data: () => ({
    option: new MultipleChoiceCheckpoint()
  }),

  created() {
    if(this.checkpoint.id) {
      this.option = new MultipleChoiceCheckpoint(this.checkpoint.handler_payload)
    }
    this.updateOptions()
  },

  methods: {
    updateOptions() {
      this.$store.commit('checkpoint/setHandlerPayload', this.option)
    }
  },

  computed: {
    ...mapGetters('checkpoint', [
      'checkpoint'
    ])
  },

  watch: {
    'options.ok_label': 'updateOptions',
    'options.failure_label': 'updateOptions',
    'options.na_label': 'updateOptions',
    'options.with_na': 'updateOptions',
  }

}
</script>